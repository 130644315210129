import './App.css';
import React from 'react'
import LogCall from './components/logCall/logCall'
import Error from './components/error/error'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/log" element={<RedirectLog />} />
          <Route path="/" element={<RedirectLog />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Navigate to="/log" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

const RedirectLog = () => {
  return (
    <div>
      <LogCall />
    </div>
  );
};

export default App;
