import config from '../../assets/config/app-settings.json';

export const postLog = async (data, token) => {
    try {
        const response = await fetch(config.backend+"/log_call", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': token
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error);
        }
        const result = await response;
        return result
    } catch (err) {
        throw err;
    }
};

export const authorization = async (data) => {
    try {
        const response = await fetch(config.backend+"/authorization", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }
        const result = await response.json();
        return result
    } catch (err) {
        throw err;
    }
};

export const getClientsDB = async (token) => {
    try {
        const response = await fetch(config.backend+"/clients", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Token': token
            }
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }
        const result = await response.json();
        return result
    } catch (err) {
        throw err;
    }
};


