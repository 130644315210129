import React, { useState, useEffect } from 'react'
import './logCall.css'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { postLog, authorization, getClientsDB } from '../../services/logCall/logCallService'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMatters, getClients } from '../../services/externalAPI/externalAPI'

function LogCall() {

    const [options, setOptions] = useState([]);

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [inputNote, setInputNote] = useState('');


    const [optionsMatter, setMatter] = useState([]);
    const [valueMatter, setValueMatter] = useState(null);
    const [inputMatter, setInputMatter] = useState('');

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    const navigateError = useNavigate();

    const [apiToken, setAPIToken] = useState("");

    const _ = require('lodash');

    useEffect(() => {
        const verifyToken = async () => {
            if (token) {
                const toastId = toast("Verifying authorization...", {
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                    type: "info"
                });
                var postToken = { token: token };
                try {
                    const result = await authorization(postToken);
                    setAPIToken(result.token);
                    toast.update(toastId, {
                        render: 'Authorized successfully.',
                        type: 'success',
                        autoClose: true,
                        closeOnClick: true,
                        draggable: true
                    });

                    var resultDB = await setClientsDB(token, result.token);
                    if(resultDB && resultDB.length === 0){
                        setClients(result.token);
                    }
                } catch (error) {
                    console.error(error);
                    navigateError("/error", { state: 0 });
                }
            } else {
                navigateError("/error", { state: 1 });
            }
        };

        verifyToken();
    }, [token]);

    const setClients = async (token) => {
        try {
            const result = await getClients(token);
            let temp = _.uniqBy(result, "name");
            temp = _.sortBy(temp, element => element.name.toLowerCase());
            setOptions(temp);
            if (result && result.length === 1) {
                setValue(temp[0]);
                setInputValue(temp[0].name)
                loadMatter(temp[0], apiToken)
            }
        } catch (error) {
            console.error(error);
            toast.error("Could not retrieve the clients.");
        }
    };

    const setClientsDB = async (token, tokenAPI) => {
        try {
            const result = await getClientsDB(token);
            let temp = _.sortBy(result, element => element.name.toLowerCase());
            setOptions(temp);
            if (result && result.length === 1) {
                setValue(temp[0]);
                setInputValue(temp[0].name)
                loadMatter(temp[0], tokenAPI)
            }
            return result
        } catch (error) {
            console.error(error);
            toast.error("Could not retrieve the clients.");
        }
    };

    const saveLog = async (event) => {
        event.preventDefault();
        if (value && valueMatter) {
            var postData = { client: value.client, note: inputNote, matter: valueMatter.matter, token: apiToken, name:value.name };
            const toastId = toast("Saving...", {
                autoClose: false,
                closeOnClick: false,
                draggable: false,
                type: "info"
            })
            try {
                const result = await postLog(postData, token);
                console.info("Log ", result);
                setInputNote("");
                setValue(null);
                setValueMatter(null);
                setInputMatter("");
                toast.update(toastId, {
                    render: 'Call log saved successfully.',
                    type: 'success',
                    autoClose: true,
                    closeOnClick: true,
                    draggable: true
                });
            } catch (error) {
                if(error){
                    var errorMessage = String(error)
                    var message = errorMessage.split(":")
                    toast.update(toastId, {
                        render: message[1],
                        type: 'error',
                        autoClose: true,
                        closeOnClick: true,
                        draggable: true
                    });
                }else{
                    toast.update(toastId, {
                        render: "Call log could not be saved.",
                        type: 'error',
                        autoClose: true,
                        closeOnClick: true,
                        draggable: true
                    });
                }
                console.error(error);
            }
        } else {
            toast.warning("You need to select a client and matter.");
        }
    }

    const loadMatter = async (data, token = "") => {
        setValue(data);
        setMatter([]);
        setValueMatter(null);
        setInputMatter("");
        if (data) {
            try {
                let result = null;
                if (token) {
                    result = await getMatters(data.client, token);
                } else {
                    result = await getMatters(data.client, apiToken);
                }
                if (result[0].matters && result[0].matters.length !== 0) {
                    setMatter(result[0].matters)
                    if (result[0].matters.length === 1) {
                        setValueMatter(result[0].matters[0]);
                        setInputMatter(result[0].matters[0].description);
                    }
                } else {
                    toast.info("The client has no matters.");
                }
            } catch (error) {
                console.error(error);
                toast.error("Could not retrieve the matters for the selected client.");
            }
        }
    }

    return (
        <div className="main-content">
            <div className="second-content">
                <span className="main-title">Log Call</span>
                <div className="autocomplete-content">
                    <span className="label-form">Select Client:</span>
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => loadMatter(newValue)}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.client === value.client}
                        className="autocomplete-size"
                        renderInput={(params) => <TextField className="textfiel-autocomplete" {...params} placeholder="Clients" />}
                    />
                </div>
                <div className="autocomplete-content-matter">
                    <span className="label-form">Select Matter:</span>
                    <Autocomplete
                        value={valueMatter}
                        onChange={(event, newInputValue) => {
                            setValueMatter(newInputValue);
                        }}
                        inputValue={inputMatter}
                        onInputChange={(event, newInputValue) => {
                            setInputMatter(newInputValue);
                        }}
                        id=""
                        options={optionsMatter}
                        getOptionLabel={(option) => option.description}
                        isOptionEqualToValue={(option, value) => option.matter === value.matter}
                        className="autocomplete-size"
                        renderInput={(params) => <TextField className="textfiel-autocomplete" {...params} placeholder="Matters" />}
                    />
                </div>

                <div className="input-group content-textarea">
                    <span className="input-group-text label-form">Note:</span>
                    <textarea className="size-textare" value={inputNote} onChange={(e) => setInputNote(e.target.value)} aria-label="With textarea"></textarea>
                </div>
                <div className="content-btn">
                    <Button variant="contained" onClick={saveLog} className="btn-save" color="primary">
                        Save
                    </Button>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </div>
    )
}

export default LogCall