import React, { useEffect } from 'react'
import './error.css'
import signal from '../../assets/img/signal-error.svg'
import { useLocation } from 'react-router'

function Error() {

    const location = useLocation();
    const numberError = location.state || 0;

    useEffect(() => {
    }, [numberError]);

    return (
        <div className="main-content">
            <div className="img-content">
                <img src={signal} alt="signal-error" />
            </div>
            <div className="content-info">
                <div className="error-title">
                    {numberError === 0 ? <span>Authentication not allowed</span> : <span>Missing Token</span>}
                </div>
                <div className="error-info">
                    {numberError === 0 ? <span>Please go back to the bot and click on the "Log Call" Button and use the new URL to be authenticated.</span>
                        : <span>Please go back to the bot and check the Token or click on the "Log Call" Button and use the new URL.</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default Error