import config from '../../assets/config/app-settings.json';

export const getMatters = async (id,token) => {
    try {
        const response = await fetch(`${config.lms_api}/matters/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }
        const result = await response.json();
        return result
    } catch (err) {
        throw err;
    }
};

export const getClients = async (token) => {
    try {
        const response = await fetch(`${config.lms_api}/clients`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }
        const result = await response.json();
        return result
    } catch (err) {
        throw err;
    }
};


